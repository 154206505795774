import "jspdf-autotable";

import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";

import { jsPDF } from "jspdf";
import { EchartService } from "src/app/shared/services/echart.service";
import { dashboardService } from "src/app/shared/services/dashboard.service";

@Component({
  selector: "dashboard-component",
  templateUrl: "./dashboard.component.html",
})
export class DashboardComponent {
  pagetitle = "Dashboard";
  isDrillDownOffersChart: boolean = false;
  userData: any;
  orders: any;
  options: any;
  chartOption: any;
  pdf: any;
  result: [];
  mySelect = "1";
  selectedValue: any;
  company_name: {};
  pieChartData: any;
  // Define variables for chart data
  liveChartData: any;
  encashmentChartData: any;
  targetChartData: any;
  champions: any;
  referredVendors: any;
  referredChampions: any;

  //QR CODE

  // PDF DOWNLOAD

  head = [["cityId", "cityName", "cityCode", "countryId", "stateName"]];

  // head = [['champioId','championType','Amount' ]]

  item = "candidoffers.com";

  qrInfo = this.item;

  createPdfChampion() {
    this.dashboardService.getChampionLists().subscribe((data: any) => {
      var doc = new jsPDF("p", "pt");

      doc.setFontSize(18);
      doc.text("Champion Amount Report", 11, 8);
      doc.setFontSize(11);
      doc.setTextColor(100);

      (doc as any).autoTable({
        head: this.head,
        // body: pdfValue,

        theme: "plain",
        startY: 30,

        bodyStyles: {
          valign: "top",
        },

        styles: {
          cellWidth: "wrap",
          rowPageBreak: "auto",
          halign: "justify",
        },

        columnStyles: {
          text: {
            cellWidth: "auto",
          },

          didDrawCell: (data) => {
            // console.log(data.column.keys, data)
          },
        },
      });

      //  PDF NEW WINDow
      doc.output("dataurlnewwindow");

      // Download PDF
      doc.save("championreport.pdf");
    });
  }

  createPdf() {
    this.dashboardService.getCities().subscribe((data: any) => {
      this.result = data.cities;

      // console.log(this.result);
      var pdfValue = this.result.map(Object.values);

      // var headvalues = this.PDFDownloadApi;
      // console.log(pdfValue)

      var doc = new jsPDF("p", "pt");

      doc.setFontSize(18);
      doc.text("City Report", 11, 8);
      doc.setFontSize(11);
      doc.setTextColor(100);

      (doc as any).autoTable({
        head: this.head,
        body: pdfValue,

        theme: "plain",
        startY: 30,

        bodyStyles: {
          valign: "top",
        },

        styles: {
          cellWidth: "wrap",
          rowPageBreak: "auto",
          halign: "justify",
        },

        columnStyles: {
          text: {
            cellWidth: "auto",
          },

          didDrawCell: (data) => {
            // console.log(data.column.keys, data)
          },
        },
      });

      //  PDF NEW WINDow
      doc.output("dataurlnewwindow");

      // Download PDF
      doc.save("cityreport.pdf");
    });
  }
  // PDF DOWNLOAD END

  constructor(
    private dashboardService: dashboardService,
    private http: HttpClient
  ) {
    this.dashboardService.getChampionLists().subscribe((data: any) => {
      // console.log(data);
      data.champions.forEach((row) => {
        this.excel.push(row);
      });
      this.champions = data.champions
      this.dashboardService
        .commonApi("/firebase/getCandidVendors")
        .subscribe((data: any) => {
          this.referredChampions = data.vendors
            .filter((vendor) =>
              this.champions.find((champ) => champ.championId == vendor.referredBy)
            )
            .map((vendor) => {
              const champion = this.champions.find((champ) => champ.championId == vendor.referredBy);
              return {
                ...vendor,
                championName: champion ? champion.championName : 'Unknown'
              };
            });
        this.referredVendors = data.vendors.filter((vendor) => {
          const referredByVendor = data.vendors.find((v) => v.vendorId === vendor.referredBy);
          return referredByVendor !== undefined;
        }).map((vendor) => {
          const referredByVendor = data.vendors.find((v) => v.vendorId === vendor.referredBy);
          return {
            ...vendor,
            referredByVendorName: referredByVendor.vendorName
          };
        });
        });
      // console.log("this.excel",this.excel);
    });
    this.dashboardService.getProductOffers().subscribe((data: any) => {
      // console.log("data",data);
      data.productOffers.forEach((row) => {
        this.excel1.push(row);
      });
      // console.log("this.excel offer upload",this.excel);
    });

    this.dashboardService.getUserTargets().subscribe((data: any) => {
      data.userTarget.forEach((row) => {
        this.excel4.push(row);
      });
      const filteredTargets = data.userTarget.filter(
        (item) => item.targetType === "N"
      );

      const usernames = filteredTargets.map((item) => item.userName);
      const targets = filteredTargets.map(
        (item) => Number(item.targetNumber) ?? 0
      );
      const achieved = filteredTargets.map(
        (item) => Number(item.targetAchieved) ?? 0
      );

      const balance = filteredTargets.map(
        (item) =>
          (Number(item.targetNumber) ?? 0) - (Number(item.targetAchieved) ?? 0)
      );

      this.targetChartData = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["Target", "Achieved", "Balance"],
        },
        xAxis: {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            rotate: 30,
          },
          data: usernames,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "Target",
            type: "bar",
            data: targets,
          },
          {
            name: "Achieved",
            type: "bar",
            data: achieved,
          },
          {
            name: "Balance",
            type: "bar",
            data: balance,
          },
        ],
      };
    });

    this.dashboardService.getVendorLists().subscribe((data: any) => {
      // console.log("data",data);
      data.vendors.forEach((row) => {
        this.excel3.push(row);
      });
      // console.log("this.excel offer upload",this.excel);
    });
    this.dashboardService.getOrders().subscribe((data: any) => {
      data.orders.forEach((row) => {
        this.excel5.push(row);
      });
    });
    this.dashboardService.getWeeklyEarning().subscribe((data: any) => {
      data.weeklyEarnings.forEach((row) => {
        this.excel6.push(row);
      });
    });
    this.dashboardService.getKitIssues().subscribe((data: any) => {
      data.kitIssues.forEach((row) => {
        this.excel7.push(row);
      });
    });

    // this.dashboardService.getCities().subscribe((data: any) => {
    //   // console.log(data);
    //   data.cities.forEach((row) => {
    //     this.excel2.push(row);
    //   });
    //   // console.log(this.result)
    // });
    this.userData = JSON.parse(localStorage.getItem("userData"));
  }

  ngOnInit() {
    this.dashboardService.getChampionLists().subscribe((data: any) => {
      // console.log(data);
    });

    this.dashboardService.getCities().subscribe((data: any) => {
      // console.log(data);
    });

    this.dashboardService.getProductOffers().subscribe((data: any) => {
      // console.log("data offer Upload",data);
    });

    this.dashboardService.getUserTargets().subscribe((data: any) => {});
    this.dashboardService.getVendorLists().subscribe((data: any) => {});
    this.dashboardService.getOrders().subscribe((data: any) => {});
    this.dashboardService.getWeeklyEarning().subscribe((data: any) => {});
    this.dashboardService.getKitIssues().subscribe((data: any) => {});

    this.chartFistPageData();
    this.fetchPieChartData();
    this.fetchLiveChartData();
    this.fetchEncashmentChartData();
    this.checkGroupName();
  }
  checkGroupName() {
    const groupName = this.userData.user.groupName;

    if (groupName) {
      if (groupName === "Super Champion") {
        this.isDrillDownOffersChart = true;
      } else if (groupName === "Admin") {
        this.isDrillDownOffersChart = true;
      } else if (groupName === "DEVELOPER") {
        this.isDrillDownOffersChart = true;
      } else if (groupName === "SUPER ADMIN") {
        this.isDrillDownOffersChart = true;
      }
    }
  }
  selectChange() {
    // let result =  this.dashboardService.getStates().subscribe((data:any)=>{
    let result = this.dashboardService
      .getChampionLists()
      .subscribe((data: any) => {
        // console.log(data);
        let nameData = data.champion.map((ele) => ele.companyName, {});
      });
  }

  excel = [];
  excel1 = [];
  excel2 = [];
  excel3 = [];
  excel4 = [];
  excel5 = [];
  excel6 = [];
  excel7 = [];

  exportAsXLSXChampion(): void {
    this.dashboardService.exportAsExcelFile(this.excel, "championdata");
  }
  exportAsXLSXCity(): void {
    this.dashboardService.exportAsExcelFile(this.excel2, "citydata");
  }
  exportAsXLSXOfferUpload(): void {
    this.dashboardService.exportAsExcelFile(this.excel1, "offerUploaddata");
  }
  exportAsXLSX(): void {
    this.dashboardService.exportAsExcelFile(this.excel4, "userTargetData");
  }
  exportAsXLSXVendor(): void {
    this.dashboardService.exportAsExcelFile(this.excel3, "vendorData");
  }
  exportAsXLSXWeeklyEarning(): void {
    this.dashboardService.exportAsExcelFile(this.excel6, "getWeeklyEarning");
  }
  exportAsXLSXOrder(): void {
    this.dashboardService.exportAsExcelFile(this.excel5, "orders");
  }
  exportAsXLSXKitIssues(): void {
    this.dashboardService.exportAsExcelFile(this.excel7, "kitIssues");
  }

  chartFistPageData() {
    // let result =  this.dashboardService.getCities().subscribe((data:any)=>{
    let result = this.dashboardService
      .getChampionLists()
      .subscribe((data: any) => {
        // console.log(data);
        // let nameData = data.cities.map(ele=>ele.cityName);
        let nameData = data.champions.map((ele) => ele.companyName);
        // let valueData = data.cities.map(ele=>ele.cityName);

        this.chartOption = {
          xAxis: {
            type: "category",
            data: nameData,
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: [100, 200, 300, 600, 507],
              type: "line",
            },
          ],
        };
      });
  }
  fetchPieChartData() {
    // Fetch data for pie chart from service
    // Dummy data for pie chart
    const pieChartOptions = {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        left: 10,
        data: ["Live", "Expired", "Out of Stock", "Encash Done"],
      },
      series: [
        {
          name: "Offer Counts",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: "30",
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            { value: 5, name: "Live" },
            { value: 5, name: "Expired" },
            { value: 5, name: "Out of Stock" },
            { value: 5, name: "Encash Done" },
          ],
        },
      ],
    };
    this.pieChartData = pieChartOptions;
  }

  // Method to fetch data for live offers chart
  fetchLiveChartData() {
    // Fetch data for live offers chart from service or API
    // For demonstration, using dummy data
    const liveChartData = {
      // Data for live by city
      cities: [
        "Mumbai",
        "Delhi",
        "Bangalore",
        "Kolkata",
        "Chennai",
        "Hyderabad",
        "Pune",
        "Ahmedabad",
        "Jaipur",
        "Surat",
      ],
      counts: [120, 95, 80, 70, 65, 60, 55, 50, 45, 40],
    };

    // Configure options for live offers bar chart
    this.liveChartData = {
      title: {
        text: "Live Offers",
        subtext: "Number of Live Offers by City",
        left: "center",
        top: "top",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "cross" },
      },
      xAxis: [
        {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            rotate: 30,
          },
          data: liveChartData.cities,
        },
      ],
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: liveChartData.counts,
          showBackground: true,
          backgroundStyle: {
            color: "rgba(180, 180, 180, 0.2)",
          },
          name: "Direct",
          type: "bar",
          yAxisIndex: 0,
          label: {
            show: true,
            position: "top",
          },
        },
      ],
    };
  }

  // Method to fetch data for encashment chart
  fetchEncashmentChartData() {
    // Fetch data for encashment chart from service or API
    // For demonstration, using dummy data
    const encashmentChartData = {
      // Data for encashments by city
      cities: [
        "New Delhi",
        "Mumbai",
        "Bangalore",
        "Chennai",
        "Kolkata",
        "Hyderabad",
        "Pune",
        "Ahmedabad",
        "Jaipur",
        "Surat",
      ],
      // Number of encashments in each city
      counts: [130, 105, 85, 75, 70, 65, 60, 55, 50, 45],
    };

    // Configure options for encashment bar chart
    this.encashmentChartData = {
      title: {
        text: "Encashment Offers",
        subtext: "Number of Encashments Offers by City",
        left: "center",
        top: "top",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "cross" },
      },
      xAxis: [
        {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            rotate: 30,
          },
          data: encashmentChartData.cities,
        },
      ],
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: encashmentChartData.counts,
          showBackground: true,
          backgroundStyle: {
            color: "rgba(180, 180, 180, 0.2)",
          },
          name: "Direct",
          type: "bar",
          yAxisIndex: 0,
          label: {
            show: true,
            position: "top",
          },
        },
      ],
    };
  }
}
