import "jspdf-autotable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/toPromise";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import * as FileSaver from "file-saver";
import { jsPDF } from "jspdf";
import * as _ from "lodash";
import * as XLSX from "xlsx";
import { environment } from "../../../environments/environment";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
const pdf_EXTENSION = ".pdf";
@Injectable({
  providedIn: "root",
})
export class dashboardService {
  [x: string]: any;
  pdf: any;

  result = [];
  head = [["cityId", "cityName", "cityCode", "countryId", "stateName"]];

  //readonly baseURL = 'http://localhost:3636';

  constructor(private http: HttpClient) {}

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  createPdf() {
    this.getCities().subscribe((data: any) => {
      this.result = data.cities;

      console.log(this.result);
      var pdfValue = this.result.map(Object.values);

      // var headvalues = this.PDFDownloadApi;
      console.log(pdfValue);

      var doc = new jsPDF("p", "pt");

      doc.setFontSize(18);
      doc.text("City Report", 11, 8);
      doc.setFontSize(11);
      doc.setTextColor(100);

      (doc as any).autoTable({
        head: this.head,
        body: pdfValue,

        theme: "plain",
        startY: 30,

        bodyStyles: {
          valign: "top",
        },

        styles: {
          cellWidth: "wrap",
          rowPageBreak: "auto",
          halign: "justify",
        },

        columnStyles: {
          text: {
            cellWidth: "auto",
          },

          didDrawCell: (data) => {
            console.log(data.column.keys, data);
          },
        },
      });

      //  PDF NEW WINDow
      doc.output("dataurlnewwindow");

      // Download PDF
      doc.save("cityreport.pdf");
    });
  }
  head1 = [
    [
      "id",
      "productDetails",
      "cityName",
      "categoriesName",
      "offerStarts",
      "offerEnds",
    ],
  ];
  createRecentOfferPdf() {
    this.getRecentOffers().subscribe((data: any) => {
      this.result = data.offers;
      //this data.offers = offers comes from ongridready gridData result['offers'] //

      console.log("recentoffer result-->", this.result);
      var pdfValue = this.result.map(Object.values);

      // var headvalues = this.PDFDownloadApi;
      console.log("Recent Offers", pdfValue);

      var doc = new jsPDF("p", "pt");

      doc.setFontSize(18);
      doc.text("Recent Offers", 11, 8);
      doc.setFontSize(11);
      doc.setTextColor(100);

      (doc as any).autoTable({
        head: this.head1,
        body: pdfValue,

        theme: "plain",
        startY: 30,

        bodyStyles: {
          valign: "top",
        },

        styles: {
          cellWidth: "wrap",
          rowPageBreak: "auto",
          halign: "justify",
        },

        columnStyles: {
          text: {
            cellWidth: "auto",
          },

          didDrawCell: (data) => {
            console.log(data.column.keys, data);
          },
        },
      });

      //  PDF NEW WINDow
      doc.output("dataurlnewwindow");

      // Download PDF
      doc.save("recentOffers.pdf");
    });
  }

  // head2 = [['id','batchId','batchTxnSeqNo','txnDate','txnAmount',"ledgerStartAmount","ledgerEndAmount"]]
  createWeeklyEarningPdf() {
    this.getWeeklyEarning().subscribe((data: any) => {
      this.result = data.weeklyEarnings;

      console.log("weeklyEarning result-->", this.result);
      var pdfValue = this.result.map(Object.values);

      // var headvalues = this.PDFDownloadApi;
      console.log("Weekly Earning", pdfValue);

      var doc = new jsPDF("p", "pt");

      doc.setFontSize(18);
      doc.text("Weekly Earnings", 11, 8);
      doc.setFontSize(11);
      doc.setTextColor(100);

      (doc as any).autoTable({
        // head: this.head2,
        body: pdfValue,

        theme: "plain",
        startY: 30,

        bodyStyles: {
          valign: "top",
        },

        styles: {
          cellWidth: "wrap",
          rowPageBreak: "auto",
          halign: "justify",
        },

        columnStyles: {
          text: {
            cellWidth: "auto",
          },

          didDrawCell: (data) => {
            console.log(data.column.keys, data);
          },
        },
      });

      //  PDF NEW WINDow
      doc.output("dataurlnewwindow");

      // Download PDF
      doc.save("weeklyEarning.pdf");
    });
  }

  getDropDownText(id, object) {
    const selObj = _.filter(object, function (o) {
      return _.includes(id, o.id);
    });
    return selObj;
  }

  getVendorRegistration(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/co-trans/getVendorRegistration",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  getCompanyTypes(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/support/getCompanyTypes",
      JSON.stringify(filter),
      { headers: headers }
    );
  }

  getChampionLists(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/co-master/getChampionLists",
      JSON.stringify(filter),
      { headers: headers }
    );
  }

  getProductOffers(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/co-trans/getProductOffers",
      JSON.stringify(filter),
      { headers: headers }
    );
  }

  getCities(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/support/getCities",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  getWeeklyEarning(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/co-trans/getWeeklyEarning",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  commonApi(url, filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + url,
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  getKitIssues(filter: object = {}) {
    const userData = JSON.parse(localStorage.getItem("userData")); // Retrieve user data from localStorage
    const userGroupName = userData.user.groupName; // Access groupName from userData

    if (
      userGroupName != "DEVELOPER" &&
      userGroupName != "SUPER ADMIN" &&
      userGroupName != "ADMIN"
    ) {
      const staffId = userData.user.staffId;
      const type = userGroupName == "Champion" ? "C" : "V";
      filter = { kitIssueToType: type, kitIssueUserId: staffId } as {
        kitIssueToType: string;
        kitIssueUserId: string;
      };
    }

    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/inv-trans/getKitIssue",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  getRecentOffers(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/co-trans/getRecentOffers",
      JSON.stringify(filter),
      { headers: headers }
    );
  }

  //Following methos is for the service call to get the Companies For Record List / Autocomplete
  getStates(filter: any = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/support/getStates",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  //Following methos is for the service call to get the User For Record List / Autocomplete
  getUserTargets(filter: any = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/support/getUserTargets",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  getVendorLists(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/co-master/getVendorLists",
      JSON.stringify(filter),
      { headers: headers }
    );
  }
  getOrders(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/co-trans/getOrders",
      JSON.stringify(filter),
      { headers: headers }
    );
  }

  downloadFile(filter: object = {}) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("userToken"),
    });
    return this.http.post(
      environment.apiCredentials.apiDomain + "/support/getCities",
      JSON.stringify(filter),
      { headers: headers }
    );
  }

  backendAPICall(eventName: string = "", dataObj: any = {}) {
    if (eventName) {
      let headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("userToken"),
      });
      return this.http.post(
        environment.apiCredentials.apiDomain + "/support/" + eventName.trim(),
        JSON.stringify(dataObj),
        { headers: headers }
      );
    }
  }
}
