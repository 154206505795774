<div class="noBottomSpace pageWrapper">
  <div class="container-fluid">
    <div class="innerPageWrapper dashboard">
      <div class="row">
        <div class="col-md-12">
          <div class="dashboard-block">
            <div class="row pt-2" *ngIf="isDrillDownOffersChart">
              <div class="col-md-6">
                <div class="live-offers-chart" *ngIf="liveChartData">
                  <div
                    echarts
                    [options]="liveChartData"
                    class="demo-chart"
                  ></div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="encashment-chart" *ngIf="encashmentChartData">
                  <div
                    echarts
                    [options]="encashmentChartData"
                    class="demo-chart"
                  ></div>
                </div>
              </div>
            </div>
            <div class="row my-5">
              <div class="col-md-6">
                <h3 class="text-center">Referred By Champions</h3>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Vendor Name</th>
                      <th>Champion Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let vendor of referredChampions">
                      <td>{{ vendor.userFullName }}</td>
                      <td>{{ vendor.championName }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6">
                <h3 class="text-center">Referred By Vendors</h3>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Vendor Name</th>
                      <th>Referred by Vendor Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let vendor of referredVendors">
                      <td>{{ vendor.userFullName }}</td>
                      <td>{{ vendor.referredByVendorName }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-6">
                <!-- QR Code -->
                <style>
                  .qr-con {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                  }
                </style>
                <h3>QR Code</h3>
                <div class="qr-con">
                  <div class="qr-code-container">
                    <qr-code [value]="qrInfo" [size]="200"></qr-code>
                  </div>
                </div>
              </div>
              <div class="col-md-6" *ngIf="isDrillDownOffersChart">
                <!-- Pie Chart -->
                <div>
                  <h3>All Offers Chart</h3>
                  <div class="pie-chart" *ngIf="pieChartData">
                    <div
                      echarts
                      [options]="pieChartData"
                      class="demo-chart"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12">
                <h3 class="text-center">User Targets Achieved & Balance</h3>
                <div class="pie-chart" *ngIf="targetChartData">
                  <div
                    echarts
                    [options]="targetChartData"
                    class="demo-chart"
                  ></div>
                </div>
              </div>
            </div>

            <div class="row m-l-0 m-r-0">
              <div class="col-lg-12 p-l-0 p-r-0 bgd-white">
                <div class="card">
                  <div class="card-body m-t-0">
                    <div class="container">
                      <button
                        (click)="exportAsXLSXKitIssues()"
                        class="btn btn-info mb-4 mt-2"
                      >
                        Download Excel
                      </button>
                      <br />
                      <h2>Issued Kits</h2>
                      <table
                        class="table table-striped table-bordered table-hover"
                      >
                        <tr>
                          <th>Kit Issue Ref No</th>
                          <th>Kit Issue Date</th>
                          <th>Kit Issue To</th>
                          <th>Kit Issue Item</th>
                          <th>Kit Issue Item Uom</th>
                          <th>Kit Issue Item Count</th>
                          <th>Kit Issue Channel</th>
                          <th>Kits Amount</th>
                        </tr>
                        <tr *ngFor="let item of excel7">
                          <td>{{ item.kitIssueReferenceNo }}</td>
                          <td>{{ item.kitIssueDate | date : "yyyy-MM-dd" }}</td>
                          <td>{{ item.kitIssueUserName }}</td>
                          <td>{{ item.kitIssueItemName }}</td>
                          <td>{{ item.kitIssueItemUomName }}</td>
                          <td>{{ item.kitIssueItemCount }}</td>
                          <td>{{ item.kitIssueItemCompanyName }}</td>
                          <td>{{ item.kitIssueKitsAmount }}</td>
                        </tr>
                      </table>
                    </div>
                    <div class="container">
                      <button
                        (click)="exportAsXLSXOrder()"
                        class="btn btn-info mb-4 mt-2"
                      >
                        Download Excel
                      </button>
                      <br />
                      <h2>Weekly Transations</h2>
                      <table
                        class="table table-striped table-bordered table-hover"
                      >
                        <tr>
                          <th>Weekly Batch Id</th>
                          <th>Remit Dispatch Id</th>
                          <th>Ledger Start Amount</th>
                          <th>Ledger End Amount</th>
                          <th>Remit Date</th>
                        </tr>
                        <tr *ngFor="let item of excel6">
                          <td>{{ item.wklyBatchId }}</td>
                          <td>{{ item.txnRemitDispatchId }}</td>
                          <td>{{ item.ledgerStartAmount }}</td>
                          <td>{{ item.ledgerEndAmount }}</td>
                          <td>
                            {{ item.txnRemitDate | date : "yyyy-MM-dd" }}
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="container">
                      <button
                        (click)="exportAsXLSXWeeklyEarning()"
                        class="btn btn-info mb-4 mt-2"
                      >
                        Download Excel
                      </button>
                      <br />
                      <h2>Order of the day</h2>
                      <table
                        class="table table-striped table-bordered table-hover"
                      >
                        <tr>
                          <th>Order Name</th>
                          <th>City Name</th>
                        </tr>
                        <tr *ngFor="let item of excel5">
                          <td>{{ item.cityName }}</td>
                          <td>{{ item.orderName }}</td>
                        </tr>
                      </table>
                    </div>

                    <div class="container">
                      <button
                        (click)="exportAsXLSX()"
                        class="btn btn-info mb-4 mt-2"
                      >
                        Download Excel
                      </button>
                      <br />
                      <table
                        class="table table-striped table-bordered table-hover"
                      >
                        <tr>
                          <th>Username</th>
                          <th>Vendor Company Name</th>
                          <th>Champion Company Name</th>
                          <th>Target Period</th>
                          <th>Target Number</th>
                          <th>Target Amount</th>
                        </tr>
                        <tr *ngFor="let item of excel4">
                          <td>{{ item.userName }}</td>
                          <td>{{ item.vendorCompanyName }}</td>
                          <td>{{ item.championCompanyname }}</td>
                          <td>{{ item.targetPeriod }}</td>
                          <td>{{ item.targetNumber }}</td>
                          <td>{{ item.targetAmount }}</td>
                        </tr>
                      </table>
                    </div>
                    <div class="container">
                      <button
                        (click)="exportAsXLSXChampion()"
                        class="btn btn-info mb-4 mt-2"
                      >
                        Download Excel
                      </button>
                      <table
                        class="table table-striped table-bordered table-hover"
                      >
                        <tr>
                          <th>Champion Code</th>
                          <th>Champion Type</th>
                          <th>Company Name</th>
                          <th>Amount</th>
                          <th>Subscription End Date</th>
                          <th>Status</th>
                        </tr>
                        <tr *ngFor="let item of excel">
                          <td>{{ item.championCode }}</td>
                          <td>{{ item.championTypeTitle }}</td>
                          <td>{{ item.companyName }}</td>
                          <td>{{ item.isFinTxnUpdated }}</td>
                          <td>{{ item.endDate }}</td>
                          <td ng-repeat="item in excel | filter : '1'">
                            {{ item.dataStatus }}
                          </td>
                          <td>{{ item.stateName }}</td>
                        </tr>
                      </table>
                    </div>
                    <div class="container">
                      <button
                        (click)="exportAsXLSXOfferUpload()"
                        class="btn btn-info mb-4 mt-2"
                      >
                        Download Excel
                      </button>
                      <table
                        class="table table-striped table-bordered table-hover"
                      >
                        <tr>
                          <th>Company Name</th>
                          <th>Offer Upload</th>
                          <th>Offer Start Date</th>
                          <th>Offer Package Title</th>
                          <th>Status</th>
                        </tr>
                        <tr *ngFor="let item of excel1">
                          <td>{{ item.companyName }}</td>
                          <td>{{ item.offerHighlights }}</td>
                          <td>{{ item.offerStart }}</td>
                          <td>{{ item.packageTitle }}</td>
                          <td ng-repeat="item in excel1 | filter : '1'">
                            {{ item.dataStatus }}
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="container">
                      <button
                        (click)="exportAsXLSXVendor()"
                        class="btn btn-info mb-4 mt-2"
                      >
                        Download Excel
                      </button>
                      <br />
                      <table
                        class="table table-striped table-bordered table-hover"
                      >
                        <tr>
                          <th>Vendor Type</th>
                          <th>Company Name</th>
                          <th>Amount</th>
                          <th>Status</th>
                        </tr>
                        <tr *ngFor="let item of excel3">
                          <td>{{ item.companyTypeTitle }}</td>
                          <td>{{ item.companyName }}</td>
                          <td>{{ item.isFinTxnUpdated }}</td>
                          <td ng-repeat="item in excel3 | filter : '1'">
                            {{ item.dataStatus }}
                          </td>
                        </tr>
                      </table>
                    </div>

                    <!-- <div class="container">
                        <button
                          (click)="exportAsXLSXCity()"
                          class="btn btn-info mb-4 mt-2"
                        >
                          Download Excel
                        </button>
                        <br />
                        <button
                          (click)="createPdf()"
                          class="btn btn-info mb-4 mt-2"
                        >
                          Download PDF
                        </button>
                        <table
                          class="table table-striped table-bordered table-hover"
                        >
                          <tr>
                            <th>City</th>
                            <th>Country</th>
                            <th>State</th>
                            <th>Status</th>
                          </tr>
                          <tr *ngFor="let item of excel2">
                            <td>{{ item.cityName }}</td>
                            <td>{{ item.countryName }}</td>
                            <td>{{ item.stateName }}</td>
                            <td ng-repeat="item in excel2 | filter : 'D'">
                              {{ item.status }}
                            </td>
                          </tr>
                        </table>
                      </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<style>
  .demo-chart {
    height: 300px;
  }
</style>
