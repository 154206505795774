<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Champion Registration</title>
  </head>

  <body>
    <div class="d-flex text-center py-3 px-5 align-items-center">
      <a class="" href="/">
        <img
          class="img-fluid"
          style="width: 10rem"
          src="../../assets/images/logo.svg"
          alt=""
        />
      </a>
      <div class="heading">Champion Registration</div>
    </div>
    <div *ngIf="loading" class="loading-container">
      <div class="spinner"></div>
      <p>Loading...</p>
    </div>

    <div class="container">
      <form
        [formGroup]="championForm"
        (ngSubmit)="onSubmit()"
        class="form"
        novalidate
      >
        <mat-accordion class="w-100" multi>
          <div class="row">
            <div class="col-md-6">
              <mat-expansion-panel [expanded]="true" class="mt-3">
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-panel-heading">
                    Document Details*
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <!-- pan input -->

                <div class="form-field">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="PAN No."
                      formControlName="panNo"
                      [readonly]="isPanVerified"
                      required
                    />
                    <mat-error
                      *ngIf="championForm.get('panNo').hasError('required')"
                    >
                      PAN No. is required</mat-error
                    >
                    <mat-error
                      *ngIf="championForm.get('panNo').hasError('pattern')"
                    >
                      Enter valid PAN No.</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="float-right">
                  <b
                    class="btn btn-info py-0"
                    *ngIf="!isPanVerified"
                    (click)="verifyPan()"
                  >
                    Verify PAN
                  </b>
                  <div *ngIf="panVerMsg" class="verification-message">
                    <mat-icon style="color: green">check_circle</mat-icon
                    >{{ panVerMsg }}
                  </div>
                </div>

                <!-- adhar input -->
                <div class="form-field">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="Aadhar No."
                      type="number"
                      formControlName="aadharNo"
                      [readonly]="isAadharVerified"
                      maxlength="12"
                      required
                    />
                    <mat-error
                      *ngIf="championForm.get('aadharNo').hasError('required')"
                    >
                      Aadhar No. is required</mat-error
                    >
                    <mat-error
                      *ngIf="championForm.get('aadharNo').hasError('pattern')"
                    >
                      Invalid Aadhar No.</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="float-right">
                  <b
                    class="btn btn-danger py-0"
                    *ngIf="!isAadharVerified"
                    (click)="sendAadharOtp()"
                  >
                    Send Otp
                  </b>
                </div>
                <div class="form-field">
                  <div *ngIf="aadharOtpSent">
                    <mat-form-field class="full-width">
                      <input
                        matInput
                        placeholder="Enter otp "
                        maxlength="6"
                        *ngIf="aadharOtpSent"
                        type="number"
                        formControlName="aadharOtp"
                        required
                      />
                    </mat-form-field>
                  </div>
                </div>

                <div class="float-right">
                  <b
                    class="btn btn-info py-0"
                    *ngIf="aadharOtpSent"
                    (click)="aadharVerify()"
                  >
                    Verify Aadhar
                  </b>
                </div>
                <div class="float-right">
                  <div *ngIf="aadharVerMsg" class="verification-message">
                    <mat-icon style="color: green">check_circle</mat-icon
                    >{{ aadharVerMsg }}
                  </div>
                </div>
                <!-- account number input -->

                <div class="form-field">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="Bank Account No."
                      type="number"
                      formControlName="bankAccountNo"
                      [readonly]="isBankVerified"
                      required
                    />
                    <mat-error
                      *ngIf="
                        championForm.get('bankAccountNo').hasError('required')
                      "
                    >
                      Bank Account No. is required</mat-error
                    >
                  </mat-form-field>
                </div>
                <!-- ifsc input -->

                <div class="form-field">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="IFSC No."
                      formControlName="bankIFSCCode"
                      [readonly]="isBankVerified"
                      maxlength="11"
                      required
                    />
                    <mat-error
                      *ngIf="
                        championForm.get('bankIFSCCode').hasError('pattern')
                      "
                    >
                      Invalid IFSC No.
                    </mat-error>
                    <mat-error
                      *ngIf="
                        championForm.get('bankIFSCCode').hasError('required')
                      "
                    >
                      IFSC No. is required</mat-error
                    >
                  </mat-form-field>
                </div>

                <div class="float-right">
                  <b
                    class="btn btn-info py-0"
                    *ngIf="!isBankVerified"
                    (click)="verifyBankAccount()"
                  >
                    Verify Bank Account
                  </b>
                  <div *ngIf="BankVerMsg" class="verification-message">
                    <mat-icon style="color: green">check_circle</mat-icon
                    >{{ BankVerMsg }}
                  </div>
                </div>

                <div class="form-field">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="Bank Account Name"
                      formControlName="bankAccountName"
                      required
                    />
                    <mat-error
                      *ngIf="
                        championForm.get('bankAccountName').hasError('required')
                      "
                      >Bank Account Name required</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="form-field">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="Bank Name"
                      formControlName="bankName"
                      required
                    />
                    <mat-error
                      *ngIf="championForm.get('bankName').hasError('required')"
                      >Bank Name required</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="form-field">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="Bank Branch Name"
                      formControlName="bankBranch"
                      required
                    />
                    <mat-error
                      *ngIf="
                        championForm.get('bankBranch').hasError('required')
                      "
                      >Bank Branch Name required</mat-error
                    >
                  </mat-form-field>
                </div>

                <!-- <div class="row mt-4 w-100">
                  
                  <div class="col-md-6">
                    <div class="form-field">
                      <mat-form-field class="full-width">
                        <mat-select
                          placeholder="Select Super Champion"
                          formControlName="parentChampionId"
                        >
                          <mat-option
                            *ngFor="let champion of champions"
                            [value]="champion.championId"
                          >
                            {{ champion.fullName }}</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                      <div
                        *ngIf="
                          cf.parentChampionId.invalid &&
                          (cf.parentChampionId.dirty ||
                            cf.parentChampionId.touched)
                        "
                        class="alert alert-danger mt10"
                      >
                        <div *ngIf="cf.parentChampionId.errors.required">
                          Parent Champion is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </mat-expansion-panel>
            </div>
            <div class="col-md-6">
              <mat-expansion-panel [expanded]="true" class="mt-3">
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-panel-heading">
                    Document Uploads*
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                  <!-- Profile Photo -->
                  <div class="form-field col-md-6">
                    <label class="file-upload-label">
                      <div class="file-upload-design">
                        <img
                          *ngIf="profilePhoto"
                          width="80"
                          height="60"
                          [src]="profilePhoto"
                          alt="BankCheque"
                        />
                        <svg
                          *ngIf="!profilePhoto"
                          viewBox="0 0 640 512"
                          height="1em"
                        >
                          <path
                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                          ></path>
                        </svg>
                        <span class="browse-button">Profile Photo </span>
                      </div>
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .pdf"
                        (change)="selectProfilePhoto($event)"
                      />
                    </label>
                  </div>

                  <div class="form-field col-md-6">
                    <label class="file-upload-label">
                      <div class="file-upload-design">
                        <img
                          *ngIf="panPhoto"
                          width="80"
                          height="60"
                          [src]="panPhoto"
                          alt="PAN"
                        />
                        <svg
                          *ngIf="!panPhoto"
                          viewBox="0 0 640 512"
                          height="1em"
                        >
                          <path
                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                          ></path>
                        </svg>
                        <span class="browse-button">PAN Photo</span>
                      </div>
                      <input
                        type="file"
                        id="panAttachment"
                        accept=".jpg, .jpeg, .png, .pdf"
                        (change)="selectPanPhoto($event)"
                      />
                    </label>
                  </div>

                  <div class="form-field col-md-6">
                    <label class="file-upload-label">
                      <div class="file-upload-design">
                        <img
                          *ngIf="aadharPhoto"
                          width="80"
                          height="60"
                          [src]="aadharPhoto"
                          alt="Aadhar"
                        />
                        <svg
                          *ngIf="!aadharPhoto"
                          viewBox="0 0 640 512"
                          height="1em"
                        >
                          <path
                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                          ></path>
                        </svg>
                        <span class="browse-button">Aadhar Photo</span>
                      </div>
                      <input
                        type="file"
                        id="aadharAttachment"
                        accept=".jpg, .jpeg, .png, .pdf"
                        (change)="selectAadharPhoto($event)"
                      />
                    </label>
                  </div>

                  <!-- Bank attachment -->
                  <div class="form-field col-md-6">
                    <label class="file-upload-label">
                      <div class="file-upload-design">
                        <img
                          *ngIf="bankChequePhoto"
                          width="80"
                          height="60"
                          [src]="bankChequePhoto"
                          alt="BankCheque"
                        />
                        <svg
                          *ngIf="!bankChequePhoto"
                          viewBox="0 0 640 512"
                          height="1em"
                        >
                          <path
                            d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                          ></path>
                        </svg>
                        <span class="browse-button">Bank Cheque Photo </span>
                      </div>
                      <input
                        type="file"
                        id="bankAttachment"
                        accept=".jpg, .jpeg, .png, .pdf"
                        (change)="selectBankChequePhoto($event)"
                      />
                    </label>
                  </div>
                </div>
              </mat-expansion-panel>
            </div>
            <div class="col-12">
              <mat-expansion-panel [expanded]="true" class="mt-3">
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-panel-heading">
                    Personnel Details*
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-form-field class="full-width w-100">
                  <input
                    placeholder="First Name"
                    matInput
                    formControlName="firstName"
                    required
                  />
                  <mat-error
                    *ngIf="championForm.get('firstName').hasError('required')"
                    >First Name is required</mat-error
                  >
                </mat-form-field>
                <mat-form-field class="full-width w-100">
                  <input
                    placeholder="Last Name"
                    matInput
                    formControlName="lastName"
                    required
                  />
                  <mat-error
                    *ngIf="championForm.get('lastName').hasError('required')"
                    >Last Name is required</mat-error
                  >
                </mat-form-field>
                <div class="form-field row mt-2">
                  <h4 class="col-md-3 ml-1 float-left">Gender *</h4>
                  <mat-radio-group
                    formControlName="gender"
                    aria-labelledby="radio-group-label"
                    class="ml-2"
                  >
                    <mat-radio-button value="M" class="mr10"
                      >Male</mat-radio-button
                    >
                    <mat-radio-button class="mr10" value="F"
                      >Female</mat-radio-button
                    >
                    <mat-radio-button value="O">Other</mat-radio-button>
                  </mat-radio-group>
                </div>
                <mat-form-field class="full-width w-100">
                  <input
                    placeholder="Father/Husband Name"
                    matInput
                    formControlName="fatherHusbandName"
                    required
                  />
                  <mat-error
                    *ngIf="
                      championForm.get('fatherHusbandName').hasError('required')
                    "
                    >Father/Husband Name is required</mat-error
                  >
                </mat-form-field>
                <div class="form-field row mt-2">
                  <h4 class="col-md-3 ml-1 float-left">Relation *</h4>
                  <mat-radio-group
                    formControlName="fatherHusbandRelation"
                    aria-labelledby="radio-group-label"
                    class="ml-2"
                  >
                    <mat-radio-button value="F" class="mr10"
                      >Father
                    </mat-radio-button>
                    <mat-radio-button value="H">Husband</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="form-field">
                  <mat-form-field class="full-width">
                    <mat-label>Mobile </mat-label>
                    <input
                      matInput
                      placeholder="Mobile"
                      formControlName="mobile"
                      [readonly]="isMobileVerified"
                      required
                      maxlength="10"
                    />
                  </mat-form-field>
                  <mat-error
                    *ngIf="
                      cf.mobile.invalid &&
                      (cf.mobile.dirty || cf.mobile.touched)
                    "
                  >
                    <mat-error *ngIf="cf.mobile.errors.required">
                      Mobile No. is required
                    </mat-error>
                    <mat-error *ngIf="cf.mobile.errors.pattern">
                      Only integer value is allowed
                    </mat-error>
                    <mat-error
                      *ngIf="
                        cf.mobile.errors.minlength || cf.mobile.errors.maxlength
                      "
                    >
                      Only 10 Digit mobile no. allowed
                    </mat-error>
                  </mat-error>

                  <div class="float-right">
                    <b
                      class="btn btn-danger py-0"
                      *ngIf="!isMobileVerified"
                      (click)="sendOTP()"
                    >
                      Send Mobile Otp
                    </b>
                  </div>
                  <div class="form-field">
                    <div *ngIf="mobileOtpSent">
                      <mat-form-field class="full-width">
                        <input
                          matInput
                          placeholder="Enter otp "
                          maxlength="4"
                          *ngIf="mobileOtpSent"
                          type="number"
                          formControlName="otp"
                          required
                        />
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="float-right">
                    <b
                      class="btn btn-info py-0"
                      *ngIf="mobileOtpSent"
                      (click)="verifyOTP()"
                    >
                      Verify Mobile
                    </b>
                  </div>
                  <div class="float-right">
                    <div *ngIf="mobileVerMsg" class="verification-message">
                      <mat-icon style="color: green">check_circle</mat-icon
                      >{{ mobileVerMsg }}
                    </div>
                  </div>
                </div>
                <!-- <div class="form-field">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="Mobile 1"
                      formControlName="mobile1"
                    />

                    <mat-error
                      *ngIf="championForm.get('mobile1').hasError('pattern')"
                    >
                      Only integer value is allowed</mat-error
                    >
                    <mat-error
                      *ngIf="
                        championForm.get('mobile1').hasError('minlength') ||
                        championForm.get('mobile').hasError('maxlength')
                      "
                    >
                      Only 10 Digit mobile no. allowed
                    </mat-error>
                  </mat-form-field>
                </div> -->
                <div class="form-field">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="Email"
                      formControlName="email"
                      [readonly]="isMailVerified"
                      required
                    />
                  </mat-form-field>
                  <mat-error
                    *ngIf="
                      cf.email.invalid && (cf.email.dirty || cf.email.touched)
                    "
                  >
                    <div *ngIf="cf.email.errors.required">
                      Email is required
                    </div>
                    <div *ngIf="cf.email.errors.email">
                      Enter valid email address
                    </div>
                  </mat-error>

                  <div class="float-right">
                    <b
                      class="btn btn-danger py-0"
                      *ngIf="!isMailVerified"
                      (click)="sendMailOTP()"
                    >
                      Send Email OTP
                    </b>
                  </div>
                  <div class="form-field">
                    <div *ngIf="mailOtpSent">
                      <mat-form-field class="full-width">
                        <input
                          matInput
                          placeholder="Enter otp"
                          maxlength="4"
                          *ngIf="mailOtpSent"
                          type="number"
                          formControlName="mailOtp"
                          required
                        />
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="float-right">
                    <b
                      class="btn btn-info py-0"
                      *ngIf="mailOtpSent"
                      (click)="verifyMailOTP()"
                    >
                      Verify Mail
                    </b>
                  </div>
                  <div class="float-right">
                    <div *ngIf="mailVerMsg" class="verification-message">
                      <mat-icon style="color: green">check_circle</mat-icon
                      >{{ mailVerMsg }}
                    </div>
                  </div>
                </div>
                <!-- <div class="form-field">
                  <mat-form-field class="full-width">
                    <input
                      matInput
                      placeholder="Email 1"
                      formControlName="email1"
                    />

                    <mat-error
                      *ngIf="championForm.get('email1').hasError('email')"
                    >
                      Enter valid email address</mat-error
                    >
                  </mat-form-field>
                </div> -->

                <div class="form-field">
                  <mat-form-field class="full-width">
                    <textarea
                      matInput
                      placeholder="Address"
                      formControlName="addressLine1"
                      required
                      rows="3"
                    ></textarea>
                    <mat-error
                      *ngIf="
                        championForm.get('addressLine1').hasError('required')
                      "
                      >Address is required</mat-error
                    >
                  </mat-form-field>
                </div>
                <div class="row">
                  <div class="form-field col-6">
                    <!-- <mat-form-field class="full-width">
                      <mat-select placeholder="City *" formControlName="cityId">
                        <mat-option
                          *ngFor="let city of cities"
                          [value]="city.cityId"
                        >
                          {{ city.cityName }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field> -->
                    <angular2-multiselect
                      [data]="cities"
                      [settings]="citySettings"
                      (onSelect)="onCitySelect($event)"
                      (onDeSelect)="onCityDeSelect($event)"
                      formControlName="selectedCity"
                    >
                    </angular2-multiselect>
                    <mat-error
                      *ngIf="
                        championForm.get('selectedCity').hasError('required')
                      "
                      >City is required</mat-error
                    >
                  </div>
                  <!-- <div class="form-field">
                    <mat-form-field class="full-width">
                      <mat-select
                        placeholder="Select State"
                        formControlName="stateId"
                      >
                        <mat-option
                          *ngFor="let state of states"
                          [value]="state.stateId"
                        >
                          {{ state.stateName }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                    <div
                      *ngIf="
                        cf.stateId.invalid &&
                        (cf.stateId.dirty || cf.stateId.touched)
                      "
                      class="alert alert-danger mt10"
                    >
                      <div *ngIf="cf.cityId.errors.required">
                        State is required
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="form-field">
                    <mat-form-field class="full-width">
                      <input
                        matInput
                        placeholder="State"
                        formControlName="state"
                        required
                      />
                      <mat-error
                        *ngIf="championForm.get('state').hasError('required')"
                        >State is required</mat-error
                      >
                    </mat-form-field>
                  </div> -->
                  <div class="form-field col-6">
                    <mat-form-field class="full-width">
                      <input
                        matInput
                        inputmode="numeric"
                        placeholder="Pincode"
                        formControlName="pincode"
                        maxlength="6"
                        required
                      />
                      <mat-error
                        *ngIf="championForm.get('pincode').hasError('required')"
                        >Pincode is required</mat-error
                      >
                    </mat-form-field>
                  </div>
                </div>
              </mat-expansion-panel>
            </div>
            <div
              class="col-12"
              *ngIf="isBankVerified && isAadharVerified && isPanVerified">
              <mat-expansion-panel [expanded]="false" class="mt-3">
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-panel-heading">
                    Payment*
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="payment-container" *ngIf="showPayment">
                  <div class="amount-display">
                    <span class="fw-bolder">Package Amount:</span>
                    <span class="amount">{{ paymentData?.amount }}</span>
                  </div>
                  <b
                    (click)="submitPayment()"
                    class="py-0 pay-now-btn btn btn-outline-success"
                    >Pay Now</b
                  >
                </div>
                <div class="payment-container" *ngIf="!showPayment">
                  <svg
                    class="succes-svg"
                    width="50"
                    style="color: green"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p class="fw-bolder">Payment Captured Successfully.</p>
                </div>
              </mat-expansion-panel>
            </div>
            <div class="col-12">
              <mat-expansion-panel [expanded]="false" class="mt-3">
                <mat-expansion-panel-header>
                  <mat-panel-title class="expansion-panel-heading">
                    Referral Details (Optional)
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-form-field class="full-width w-100">
                  <input
                    matInput
                    placeholder="Referral User ID"
                    formControlName="referralId"
                  />
                </mat-form-field>
              </mat-expansion-panel>
              <div class="col-12">
                <mat-expansion-panel [expanded]="false" class="mt-3">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="expansion-panel-heading">
                      Terms and Conditions
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="terms-container">
                    <div class="terms-content">
                      <h4>Terms and Conditions for Champions</h4>
                      <p *ngFor="let term of termsAndConditions">{{ term.tnc }}</p>
                    </div>
                  </div>
                  <mat-checkbox formControlName="agreedToTerms" class="mt-3">
                    I have read and agree to the Terms and Conditions
                  </mat-checkbox>
                </mat-expansion-panel>
              </div>
            </div>
          </div>
        </mat-accordion>
        <button
          type="submit"
          [disabled]="
            !isBankVerified ||
            !isAadharVerified ||
            !isPanVerified ||
            showPayment
          "
          class="login-button"
        >
          Submit
        </button>
      </form>
    </div>
  </body>
</html>
